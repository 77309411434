










































































































































































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import FilePicker from "@/components/Input/FilePicker.vue";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { VueEditor } from "vue2-editor";
import { EditorImages } from "@/mixins/editor-images";
import { StyleCheck } from "@/mixins/style-check";
import { FileCheck } from "@/mixins/file-check";
import VueCountryCode from "vue-country-code";
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    PageTitle,
    Loader,
    Empty,
    VueEditor,
    FilePicker,
    VueCountryCode,
    Carousel,
    Slide,
  },
})
export default class Company extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  EditorImages,
  StyleCheck,
  FileCheck
) {
  $refs!: {
    companyInfoForm: HTMLFormElement;
    companyAddressForm: HTMLFormElement;
    logoAndCarouselForm: HTMLFormElement;
  };
  loader = false;
  loading = false;
  showErrorDescription = false;
  updatable = false;
  dialogCancelUpdate = false;
  changeProgressText = false;

  defaultCompany = {
    id: "",
    identification: "",
    name: "",
    title: "",
    description: "",
    email: "",
    phone: "",
    address: {
      primary_line: "",
      secondary_line: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
    },
  };

  company = { ...this.defaultCompany };
  companyPhoneCountry: { dialCode: string; iso2: string } = {
    dialCode: "",
    iso2: "",
  };
  companyPhone = "";
  defaultSystem: {
    logo: { preview: string; file: File | null };
    carousel: { files: Array<File>; filesPreview: Array<string> };
  } = {
    logo: {
      preview: "",
      file: null,
    },
    carousel: {
      files: [],
      filesPreview: [],
    },
  };

  system = { ...this.defaultSystem };

  private async created() {
    this.loader = true;
    await this.$store.dispatch("system/getSystemInfo").catch(() => {
      const Error: Notification = {
        message: this.$tc("Company.fetchError.get"),
        timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
        top: true,
        bottom: false,
        left: false,
        right: false,
        currentPath: this.$route.fullPath,
        error: true,
      };

      this.$store.dispatch("notifications/showNotification", Error);
    });
    this.getCompanyInfo();
    this.loader = false;
  }

  private getCompanyInfo() {
    let company = this.$store.getters["system/getCompany"];
    let carousel = this.$store.getters["system/getCarousel"];
    this.system.carousel.filesPreview = [];
    this.system.carousel.files = [];
    carousel.forEach((image) => {
      this.system.carousel.files.push(image);
      this.system.carousel.filesPreview.push(image.url);
    });
    this.system.logo.preview = company.logo.url;
    this.companyPhoneCountry.iso2 = company.phone
      ?.split(" ")[2]
      .split("(")[1]
      .split(")")[0];
    this.companyPhoneCountry.dialCode = company.phone?.split(" ")[0].slice(1);
    this.company = { ...company, phone: company.phone?.split(" ")[1] };
    this.company.address = { ...company.address };
  }

  @Watch("system.logo.file")
  previewFile(file: File): void {
    if (file != null) {
      this.system.logo.preview = URL.createObjectURL(file);
    }
  }

  @Watch("company.phone")
  setCompanyPhone(phone: string): void {
    const clearPhone = phone != null ? phone : "";
    this.companyPhone =
      "+" +
      this.companyPhoneCountry.dialCode +
      " " +
      clearPhone +
      " (" +
      this.companyPhoneCountry.iso2 +
      ")";
  }
  selectedCompanyCountry(country: any): void {
    this.companyPhoneCountry = country;
    this.companyPhone =
      "+" +
      this.companyPhoneCountry.dialCode +
      " " +
      (this.company.phone == null ? "" : this.company.phone) +
      " (" +
      this.companyPhoneCountry.iso2 +
      ")";
  }
  previewCarousel(files: Array<File>): void {
    if (files.length > 0 && this.system.carousel.files.length > 0) {
      files.forEach((file: File) => {
        this.system.carousel.filesPreview.push(URL.createObjectURL(file));
      });
    } else {
      this.system.carousel.filesPreview = [];
    }
  }

  @Watch("system", { deep: true })
  @Watch("company", { deep: true })
  @Watch("company.address", { deep: true })
  @Watch("companyPhone")
  private canUpdate() {
    let company = this.$store.getters["system/getCompany"];
    if (
      this.company.identification != company.identification ||
      this.company.name != company.name ||
      this.company.title != company.title ||
      this.companyPhone != company.phone ||
      this.company.email != company.email ||
      this.company.description != company.description ||
      this.company.address.primary_line != company.address.primary_line ||
      this.company.address.secondary_line != company.address.secondary_line ||
      this.company.address.city != company.address.city ||
      this.company.address.state != company.address.state ||
      this.company.address.country != company.address.country ||
      this.company.address.zip_code != company.address.zip_code ||
      this.system.logo.preview != company.logo.url ||
      this.detectChangeOnCarouselImages()
    ) {
      this.updatable = true;
    } else {
      this.updatable = false;
    }
  }

  private detectChangeOnCarouselImages() {
    let carousel = this.$store.getters["system/getCarousel"];
    const aux: any[] = [];

    carousel.forEach((image: any) => {
      aux.push(image.url);
    });

    return !(
      JSON.stringify(this.system.carousel.filesPreview) == JSON.stringify(aux)
    );
  }

  private cancelUpdate(): void {
    this.company = Object.assign(this.company, this.defaultCompany);
    this.system = Object.assign(this.system, this.defaultSystem);
    this.getCompanyInfo();
    this.dialogCancelUpdate = false;
  }

  private get logoProgress(): boolean {
    return this.$store.getters["settings/getLogoProgress"];
  }

  private get carouselProgress(): boolean {
    return this.$store.getters["settings/getCarouselProgress"];
  }

  private async updateCompanyInfo(): Promise<void> {
    this.loading = true;
    if (
      this.$refs.companyInfoForm.validate() &&
      this.$refs.companyAddressForm.validate() &&
      this.$refs.logoAndCarouselForm.validate() &&
      this.company.description !== ""
    ) {
      this.company.phone = this.companyPhone;
      const payload = new FormData();
      payload.append(
        "LOGO",
        this.system.logo.file == null ? "" : this.system.logo.file
      );
      payload.append(
        "DATA",
        JSON.stringify({ company: this.company, address: this.company.address })
      );

      await this.$store
        .dispatch("settings/updateCompanyInfo", {
          info: payload,
          id: this.company.id,
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Company.fetchError.updateInfo"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .then(async () => {
          const notification: Notification = {
            message: this.$tc("Company.successInfo"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
          };

          this.$store.dispatch("notifications/showNotification", notification);
        });

      if (this.detectChangeOnCarouselImages()) {
        this.changeProgressText = true;
        const payloadCarousel = new FormData();
        this.system.carousel.files.forEach((file) => {
          payloadCarousel.append("CAROUSEL", file);
        });

        await this.$store
          .dispatch("settings/setCarousel", { files: payloadCarousel })
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("Company.fetchError.updateCarousel"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          })
          .then(async () => {
            const notification: Notification = {
              message: this.$tc("Company.successCarousel"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
            };

            this.$store.dispatch(
              "notifications/showNotification",
              notification
            );
          });
      }

      await this.$store.dispatch("system/getSystemInfo").catch(() => {
        const Error: Notification = {
          message: this.$tc("Company.fetchError.get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
      this.getCompanyInfo();
      this.changeProgressText = false;
      this.$store.dispatch("settings/clearProgress");
      this.loading = false;
    } else {
      if (this.company.description == "") {
        this.showErrorDescription = true;
      }
      this.loading = false;
    }
  }

  @Watch("company.description")
  private errorHandler(): void {
    if (this.company.description !== "") {
      this.showErrorDescription = false;
    } else {
      this.showErrorDescription = true;
    }
  }
}
